
































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useStoryblokStyles } from '~/modules/storyblok/composables';

export default defineComponent({
  name: 'Row',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      justifyContent,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      marginTop,
      marginBottom,
      background,
      pcAlign,
      tabletAlign,
      align,
      alignItems,
      id,
    } = props.blok;

    const {
      backgroundStyle,
      justifyContentStyle,
      alignItemsStyle,
      paddingTopStyle,
      paddingBottomStyle,
      paddingLeftStyle,
      paddingRightStyle,
      marginTopStyle,
      marginBottomStyle,
      centerTextStyle,
    } = useStoryblokStyles();

    const backgroundColor = computed(() => backgroundStyle(background as string));
    const justifyContentClass = computed(() => justifyContentStyle(justifyContent as string));
    const alignItemsClass = computed(() => alignItemsStyle(alignItems as string));
    const paddingTopClass = computed(() => paddingTopStyle(paddingTop as string));
    const paddingBottomClass = computed(() => paddingBottomStyle(paddingBottom as string));
    const paddingLeftClass = computed(() => paddingLeftStyle(paddingLeft as string));
    const paddingRightClass = computed(() => paddingRightStyle(paddingRight as string));
    const marginTopClass = computed(() => marginTopStyle(marginTop as string));
    const marginBottomClass = computed(() => marginBottomStyle(marginBottom as string));
    const centerTextClass = computed(() => centerTextStyle({ pcAlign, tabletAlign, align }));
    // const stylesForBackgroundColor = backgroundColor.value ? 'mx-auto sm:mx-4 sm:rounded-md container' : 'w-full';

    return {
      // stylesForBackgroundColor,
      backgroundColor,
      justifyContentClass,
      alignItemsClass,
      paddingTopClass,
      paddingBottomClass,
      paddingLeftClass,
      paddingRightClass,
      marginTopClass,
      marginBottomClass,
      centerTextClass,
      id,
    };
  },
});
